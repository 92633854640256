<template>
    <div class="gfs-bg">

        <div class="l-content" style="margin-top: 1.1rem; font-size: 14px;">


            
            <van-row type="flex" justify="center" class="headerTitle">
                <van-col span="1">ID</van-col>
                <van-col span="2">Business Name</van-col>
                <van-col span="2">Website/Linkin/FB</van-col>
                <van-col span="2">type</van-col>
                <van-col span="2">Contact person</van-col>
                <van-col span="2">Phone</van-col>

                <van-col span="2">Country</van-col>
                <van-col span="2">State</van-col>
                <van-col span="2">City</van-col>
                <van-col span="1">Country code</van-col>
                <van-col span="2">Street Address</van-col>
                <van-col span="2">PostCode</van-col>
                <van-col span="1">Operate</van-col>
            </van-row>

            <van-row type="flex" justify="center" v-for="(item, i) in dataLists" :key="i">
                <van-col span="1">{{item.id}}</van-col>
                <van-col span="2">{{item.businessName}}</van-col>
                <van-col span="2">{{item.socialAccounts}}</van-col>
                <van-col span="2">{{item.typeStr}}</van-col>
                <van-col span="2">{{item.contactPerson}}</van-col>
                <van-col span="2">{{item.phone}}</van-col>

                <van-col span="2">{{item.countryNameEN}}</van-col>
                <van-col span="2">{{item.stateNameEN}}</van-col>
                <van-col span="2">{{item.cityNameEN}}</van-col>
                <van-col span="1">{{item.countryShortCode}}</van-col>
                <van-col span="2">{{item.streetAddress}}</van-col>
                <van-col span="2">{{item.postCode}}</van-col>
                <van-col span="1">
                    <p 
                        style="background-color: red;" 
                        @click="createLine(item)">Create</p>
                    <p 
                        @click="deleteLine(item)"
                        v-if="isAdmin"
                        style="background-color: #99CFD9; margin-top: 0.5rem;">Delete</p>
                </van-col>
            </van-row>

            <van-pagination 
                style="margin-top: 1.1rem;"
                v-model="currentPage" 
                force-ellipses
                :total-items="totalItems" 
                :items-per-page="itemsPerPage"
                @change="onChange" />
        </div>
    </div>
</template>


<script>
    import '@/assets/less/get-free-samples.less';
    import Vue from 'vue';
    import { 
        Form, Field, Button, RadioGroup, Radio, Picker, 
        Popup, Toast, Dialog, Pagination, Col, Row
    } from 'vant';
    import axios from 'axios';
    
    Vue.use(Col);
    Vue.use(Row);
    Vue.use(Pagination);
    Vue.use(Dialog);
    Vue.use(Toast);
    Vue.use(Picker);
    Vue.use(Popup);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    Vue.use(Button);
    Vue.use(Field);
    Vue.use(Form);
    
	export default {
		name: 'GetFreeSamplesLines',
		props: [
		],
		components: {
		},
		data() {
            const query = this.$route.query;
			return {
                currentPage: 1,
                totalItems: 0,
                itemsPerPage: 20,
                dataLists: [],
                aPageKey: '37f4351a5ab27aa844403396b3bf179669d25554',
                isAdmin: (
                    (
                        typeof query == 'object' && null != query &&
                        (
                            typeof query.admin == 'number' ||
                            typeof query.admin == 'string'
                        ) && 1 == query.admin
                    ) ? true : false
                )
            }
		},
        methods: {
            createLine(item) {
                const that = this;
                const query = that.$route.query;
                Dialog.confirm({
                    title: 'Are you sure to create account?',
                    message: "ID: "+item.id+"\n"+
                                "Business Name: "+item.businessName+"\n",
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel'
                })
                .then(() => {
                    console .log('queryquery', query)

                    Toast.loading({
                        message: 'Create account...',
                        forbidClick: true,
                        duration: 0
                    });
                    axios({
                        url: '/mobileApi',
                        method: "post",
                        crossdomain: true,
                        params: Object.assign({}, {
                            cpAction: 'createFreeSamplesContacts',
                            id: item.id
                        }, {
                            roomcode: (
                                    typeof query.roomcode == 'string' ||
                                    typeof query.roomcode == 'number'
                                ) ? query.roomcode : '',
                            roomname: (
                                    typeof query.roomname == 'string' ||
                                    typeof query.roomname == 'number'
                                ) ? query.roomname : '',
                            salescode: (
                                    typeof query.salescode == 'string' ||
                                    typeof query.salescode == 'number'
                                ) ? query.salescode : '',
                            salesname: (
                                    typeof query.salesname == 'string' ||
                                    typeof query.salesname == 'number'
                                ) ? query.salesname : ''
                        })
                    }).then(res => {
                        if (typeof res == 'object' && null != res &&
                            typeof res.data == 'object' && null != res.data && 
                            res.data.code != 1) {
                            Toast(res.data.msg);
                            return;
                        }
                        Toast.clear();
                        that.onChange(that.getParamCurrentPage());
                        console.log('create account res', res.data);
                    }).catch(() => {
                        Toast.clear();
                    });
                })
                .catch(() => {
                    // on cancel
                });
            },
            deleteLine(item) {
                const that = this;
                Dialog.confirm({
                    title: 'Are you sure to delete?',
                    message: "ID: "+item.id+"\n"+
                                "Business Name: "+item.businessName+"\n",
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel'
                })
                .then(() => {
                    Toast.loading({
                        message: 'delete...',
                        forbidClick: true,
                        duration: 0
                    });
                    axios({
                        url: '/mobileApi',
                        method: "post",
                        crossdomain: true,
                        params: {
                            cpAction: 'deleteFreeSamplesContacts',
                            id: item.id
                        }
                    }).then(res => {
                        if (typeof res == 'object' && null != res &&
                            typeof res.data == 'object' && null != res.data && 
                            res.data.code != 1) {
                            Toast(res.data.msg);
                            return;
                        }
                        Toast.clear();
                        that.onChange(that.getParamCurrentPage());
                        console.log('delete res', res.data);
                    }).catch(() => {
                        Toast.clear();
                    });
                })
                .catch(() => {
                    // on cancel
                });
            },
            onChange(currentPage) {
                const that = this;

                Toast.loading({
                    message: 'loading...',
                    forbidClick: true,
                    duration: 0
                });
                axios({
                    url: '/mobileApi',
                    method: "post",
                    crossdomain: true,
                    params: {
                        cpAction: 'getFreeSamplesContacts',
                        currentPage: currentPage,
                        itemsPerPage: that.itemsPerPage
                    }
                }).then(res => {
                    Toast.clear();
                    const datas = res.data;
                    that.totalItems = datas.totalRowsCount;
                    that.currentPage = datas.currentPage;
                    that.dataLists = datas.dataLists;

                    if (that.currentPage != that.getParamCurrentPage()) {
                        that.$router.push({
                            path: '/get-free-samples-contacts', 
                            query: {
                                aPageKey: that.aPageKey,
                                currentPage: that.currentPage
                            }
                        });
                        return;
                    }
                    console.log('submit res', currentPage, res.data);
                }).catch(() => {
                    Toast.clear();
                });
            },

            /**
             * 得到地址栏currentPage
             */
            getParamCurrentPage() {
                const query = this.$route.query;
                let currentPage = 1;
                if (typeof query == 'object' && null != query &&
                    (
                        typeof query.currentPage == 'string' ||
                        typeof query.currentPage == 'number'
                    ) && /^[0-9]{1,}$/gi.test(query.currentPage) &&
                    parseInt(query.currentPage) >= 1) {
                        currentPage = parseInt(query.currentPage)
                }
                return currentPage;
            }
        },
        created() {
            /**
             * 是否显示头部
             */
            this.$parent.isShowHeader = false;
            this.$parent.setHeaderMarginTop();

            const query = this.$route.query;
            if (typeof query != 'object' || null == query ||
                typeof query.aPageKey != 'string' ||
                query.aPageKey != this.aPageKey) {
                    // 错误的页面访问
                    this.$router.push({
                        path: '/get-free-samples', 
                        query: {
                        }
                    });
                    return;
            }
            
            this.onChange(this.getParamCurrentPage());
        },
        mounted() {
        }
	}
</script>